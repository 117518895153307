<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 훈련구분 -->
          <c-select
            type="search"
            codeGroupCd="EAP_EVAL_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="trainingTypeCd"
            label="LBL0002654"
            v-model="searchParam.trainingTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 훈련기간 -->
          <c-datepicker
            :range="true"
            label="LBL0002655"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
      </template>
    </c-search-box>
    <!-- 비상대응 훈련 목록 -->
    <c-table
      ref="table"
      title="LBL0002656"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :usePaging="false"
      :filtering="false"
      rowKey="sopEmergencyTrainingId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 선택 -->
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'traningPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'trainingName',
            field: 'trainingName',
            // 훈련명
            label: 'LBL0002657',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'trainingDate',
            field: 'trainingDate',
            // 훈련일시
            label: 'LBL0002658',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'eapTrainingTypeName',
            field: 'eapTrainingTypeName',
            // 훈련구분
            label: 'LBL0002659',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'trainingArea',
            field: 'trainingArea',
            // 훈련장소
            label: 'LBL0002660',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'trainingCompleteFlagName',
            field: 'trainingCompleteFlagName',
            // 훈련완료여부
            label: 'LBL0002661',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'trainingInstructUserNames',
            field: 'trainingInstructUserNames',
            // 교관
            label: 'LBL0002662',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
        ],
        data: [],
        height: '500px'
      },
      searchParam: {
        plantCd: null,
        trainingTypeCd: null,
        startYmd: '',
        endYmd: '',
        period: [],
      },
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.eap.training.plan.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = 'LBL0002663'; // 비상대응 훈련 상세
      this.popupOptions.param = row
      this.popupOptions.target = () => import(`${'./traningDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
